<template>
  <div :class="$style.mainPage">
    <div :class="$style.mainPageContent">
      <template v-if="filteredPageBlocks.length > 0">
        <article v-if="promoblock" :class="$style.mainBlock">
          <promo-kinoms-slider
            :items="promoblock?.contentMomentsList || []"
            :title="promoblock.title"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'promoBlock')"
            :style="{ order: 1 }"
            @on-slide-left="mainPageAnalytics.onPromoHeaderSliderSwipedLeft()"
          />
        </article>

        <article v-if="data?.continueWatchItems?.length" :class="$style.mainBlock">
          <continue-watch
            :key="data?.continueWatchItems?.length"
            :items="data?.continueWatchItems"
            :class="{
              [$style.continueWatch]: true,
              [$style.continueWatchAsFirstItem]: !promoblock,
            }"
            :style="{ order: 2 }"
            @on-slide-left="mainPageAnalytics.onContinueWatchItemBlockSwipedLeft()"
          />
        </article>

        <article
          v-for="(block, index) in filteredPageBlocks"
          :key="index"
          :class="$style.mainBlock"
          :style="{ order: block.position }"
        >
          <div
            v-if="block.displayType === DisplayType.ContentMomentList"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'playlist')"
            :class="$style.contentMomentList"
          >
            <app-link
              :to="{
                name: $AppRoute.PlaylistItem,
                params: {
                  id: block.playlistSlug || block.playlistId,
                },
              }"
              :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Playlists, 'linkTitle')"
              :class="$style.contentMomentListTitle"
            >
              <h2 :class="$style.contentMomentListTitleName">{{ block.title }}</h2>
            </app-link>

            <moments-list-vertical
              :items="block?.contentMomentsList || []"
              :playlist-title="block.title"
              :class="$style.contentMomentListPlayer"
            />
          </div>

          <recommendations-list
            v-if="block.displayType === DisplayType.DsmlRecommendations && recommendations?.length"
            :items="recommendations"
            :class="$style.recommendations"
            :title="$t('page.main.recommendationsTitle')"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'recommendations')"
            @on-slide-left="mainPageAnalytics.onDsmlRecommendationsBlockSwipedLeft()"
          />

          <div
            v-if="block.displayType === DisplayType.OffersList && block.offerId"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'promoBanner')"
            :class="$style.mainPromoBanner"
          >
            <promo-offer-card
              v-if="data?.promoOffer"
              :bullets="data?.promoOffer.bullets"
              :image="data?.promoOffer.image"
              :title="data?.promoOffer.title"
              :placement-route="AppRoute.Index"
              :class="$style.promoOffer"
            >
              <div :class="$style.promoOfferButtonsBlock">
                <app-button
                  variation="button-secondary"
                  :text="data?.promoOffer.btnName"
                  :class="$style.promoOfferButton"
                  :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Common, 'offerButton')"
                  @click="onPromoOfferButtonClick(block.offerId)"
                ></app-button>
                <span :class="$style.promoOfferText">{{ data?.promoOffer?.infoText }}</span>
              </div>
            </promo-offer-card>
          </div>

          <belt-list
            v-if="block.displayType === DisplayType.GenresBelt"
            :items="block.beltItems"
            :title="block.title"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'belt')"
          />

          <tv-channels-list
            v-if="block.displayType === DisplayType.TvChannelsList && data?.channels?.length"
            :channels="data?.channels"
            :title="block.title"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'channelsList')"
          />
        </article>
      </template>

      <div v-if="isLoading" :class="$style.skeletonContainer">
        <header-slider-skeleton />
        <recommendations-list-skeleton />
        <moments-list-skeleton />
        <player-playlist-skeleton />
      </div>
    </div>

    <scroll-top />
  </div>
</template>

<script lang="ts" setup>
import { useMainPageAnalytics } from '@package/sdk/src/analytics';
import type { Channel, MainPage, MainPageBlock, Media, Moment } from '@package/sdk/src/api';
import { DisplayType, FeatureToggle } from '@package/sdk/src/api';
import { getFirstElement } from '@package/sdk/src/core';
import { isClient } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import type { MappedBannerOffer } from '@/code/auth/use-auth-actions';
import useAuthActions from '@/code/auth/use-auth-actions';
import { useBreadcrumbs } from '@/code/breadcrumbs/use-breadcrumbs';
import { useChannelsApi } from '@/code/channels/use-channels-api';
import { useMainPageContent } from '@/code/content/use-main-page-content';
import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import { useOffersApi } from '@/code/offer/use-offers-api';
import { SeoMetaPage, usePageMeta } from '@/code/seo/use-page-meta';
import HeaderSliderSkeleton from '@/components/header-slider/HeaderSliderSkeleton.vue';
import BeltList from '@/components/main-page/BeltList.vue';
import ContinueWatch from '@/components/main-page/ContinueWatch.vue';
import MomentsListVertical from '@/components/main-page/MomentsListVertical.vue';
import PromoKinomsSlider from '@/components/main-page/PromoKinomsSlider.vue';
import RecommendationsList from '@/components/main-page/RecommendationsList.vue';
import PromoOfferCard from '@/components/offers-page/PromoOfferCard.vue';
import ScrollTop from '@/components/ScrollTop.vue';
import MomentsListSkeleton from '@/components/skeleton/MomentsListSkeleton.vue';
import PlayerPlaylistSkeleton from '@/components/skeleton/PlayerPlaylistSkeleton.vue';
import RecommendationsListSkeleton from '@/components/skeleton/RecommendationsListSkeleton.vue';
import TvChannelsList from '@/components/tv-page/TvChannelsList.vue';
import AppButton from '@/components/ui/AppButton.vue';
import AppLink from '@/components/ui/AppLink.vue';
import { AppRoute } from '@/platform/router/routes';
import { useFeaturesStore } from '@/stores/use-features-store';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useSessionStore } from '@/stores/use-session-store';

definePageMeta({
  layout: 'default',
});

const { fetchChannelsRecommendations } = useChannelsApi();
const { fetchBlocks, fetchRecommendations, fetchWatchingItems } = useMainPageContent();
const mainPageAnalytics = useMainPageAnalytics();

const { mapPromoOfferToBanner, navigateToPayment, navigateToAuth } = useAuthActions();
const { injectAdriverScript, removeExistingAdriverScript } = useKmzaDriverEvents();

const PAGE_SIZE = 3;
const currentPage = ref(1);
const pageCount = ref(20);

const offersApi = useOffersApi();

const { getStatus } = useFeaturesStore();
const { isAuth } = storeToRefs(useSessionStore());
const { setupSeoMetadata } = usePageMeta();
const { setDefaultBreadcrumbs } = useBreadcrumbs();
const { clearScrollPosition } = useLayoutStore();
const route = useRoute();

const recommendations = ref<Media[]>();

const filteredPageBlocks = computed<MainPageBlock[]>(() => {
  if (!data.value?.blocks) {
    return [];
  }

  return data.value.blocks.filter(
    (block: MainPageBlock) =>
      block.displayType !== DisplayType.MomentList && block.displayType !== DisplayType.PromoBlock,
  );
});

const promoblock = computed(() => {
  if (!data.value?.blocks) {
    return undefined;
  }

  return data.value.blocks.find((block) => block.displayType === DisplayType.PromoBlock);
});

const onPromoOfferButtonClick = (offerId: string) => {
  injectAdriverScript(AdriverAnalyticEvent.PromoTryButton);

  return isAuth.value ? navigateToPayment(offerId) : navigateToAuth({ offerId });
};

const setupMetadata = (result: MainPage) => {
  const firstContentRow = result[0]?.contentMomentsList;

  if (!firstContentRow) {
    return;
  }

  const firstMoment = getFirstElement(firstContentRow) as Moment;

  setupSeoMetadata({ page: SeoMetaPage.Home, args: [firstMoment] });
};

const fetchChannels = () => {
  if (!getStatus({ name: FeatureToggle.TvChannelOnMain })) {
    return Promise.resolve([]);
  }

  return fetchChannelsRecommendations();
};

const {
  pending: isLoading,
  data,
  clear,
  error,
} = await useLazyAsyncData(
  async () => {
    let blocks: MainPageBlock[] = [];
    let channels: Channel[] = [];
    let continueWatchItems: Media[] = [];

    if (isClient && data.value?.continueWatchItems) {
      continueWatchItems = data.value?.continueWatchItems;
    }

    const [blocksPromiseResult, channelsPromiseResult, continueWatchItemsPromiseResult] = await Promise.allSettled([
      fetchBlocks({ page: 1 }),
      fetchChannels(),
      continueWatchItems.length > 0 ? Promise.resolve(continueWatchItems) : fetchWatchingItems(),
    ]);

    if (blocksPromiseResult.status === 'fulfilled') {
      blocks = blocksPromiseResult.value;
    }

    if (channelsPromiseResult.status === 'fulfilled') {
      channels = channelsPromiseResult.value;
    }

    if (continueWatchItemsPromiseResult.status === 'fulfilled') {
      continueWatchItems = continueWatchItemsPromiseResult.value;
    }

    const promoOfferId = blocks.find((block) => block.displayType === DisplayType.OffersList)?.offerId;
    let promoOffer: MappedBannerOffer | undefined;

    if (promoOfferId) {
      const fetchedOffer = await offersApi.getById(promoOfferId);

      if (fetchedOffer) {
        promoOffer = mapPromoOfferToBanner(fetchedOffer);
      }
    }

    setupMetadata(blocks as MainPage);

    if (isClient && data.value?.blocks?.length && currentPage.value !== 1) {
      const items = [...(data.value?.blocks || []), ...blocks] as MainPageBlock[];

      if (blocks.length <= 2) {
        pageCount.value = Math.ceil(items.length / PAGE_SIZE);
      }

      return { blocks: items, channels, promoOffer, continueWatchItems };
    }

    return {
      blocks,
      channels,
      continueWatchItems,
      promoOffer,
    };
  },
  { server: true },
);

setDefaultBreadcrumbs();

if (error.value) {
  console.error(error.value);
}

onMounted(async () => {
  injectAdriverScript(AdriverAnalyticEvent.RecommendationsList);
  recommendations.value = await fetchRecommendations();
});

onBeforeUnmount(() => {
  clear();

  removeExistingAdriverScript(AdriverAnalyticEvent.RecommendationsList);
  clearScrollPosition(route.name);
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '../assets/breakpoints' as breakpoints;

.mainPage {
  overflow: hidden;
  margin-bottom: var(--g-spacing-40);
}

.mainPageContent {
  display: flex;
  flex-direction: column;
}

.recommendations {
  padding: 0 var(--g-spacing-48) 0;
}

.contentMomentListCategory {
  color: var(--color-text-tertiary);
}

.contentMomentListTitle {
  @include fonts.WebTitle-2;
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-top: var(--g-spacing-6);
  cursor: pointer;
}

.contentMomentListTitleName {
  @include fonts.WebTitle-2;
  margin-bottom: 0;
}

.contentMomentListPlayer {
  margin-top: var(--g-spacing-32);
}

.momentsListTitle {
  @include fonts.WebTitle-2;
  max-width: 50%;
  margin-top: 90px;
}

.continueWatch,
.mainPromoBanner,
.contentMomentList {
  padding: 0 var(--g-spacing-48);
  overflow: hidden;
}

.mainBlock {
  display: flex;
  flex-direction: column;
}

.mainBlock:not(:last-child) {
  margin-bottom: 80px;
}

.mainPromoBanner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.promoOfferButtonsBlock {
  display: flex;
  align-items: center;
  margin-top: auto;
}

.promoOfferButton {
  margin-right: var(--g-spacing-32);
  padding: var(--g-spacing-12) var(--g-spacing-40);
  border-radius: var(--g-border-round-12);
  background-color: var(--color-notheme-bg-banner-button);
}

.promoOfferText {
  @include fonts.WebCaption-1;
}

.continueWatch {
  margin: 0;
}

.continueWatchAsFirstItem {
  margin: 0;
  margin-bottom: 80px;
}

.skeletonContainer {
  padding: 0 var(--g-spacing-32);

  > :deep(*:not(:last-child)) {
    margin-bottom: 80px;
  }
}

.promoBlock {
  position: relative;
  padding: 0 var(--g-spacing-32);
  overflow: hidden;
}

@include breakpoints.max-width-800 {
  .mainBlock:not(:last-child) {
    margin-bottom: var(--g-spacing-40);
  }

  .recommendations {
    padding: 0 var(--g-spacing-20);
  }

  .momentsListTitle {
    max-width: 100%;
  }
  .continueWatch,
  .contentMomentList,
  .mainPromoBanner {
    padding: 0 var(--g-spacing-20);
  }

  .promoBlock {
    padding: 0 var(--g-spacing-16);
  }

  .continueWatch {
    margin-top: var(--g-spacing-40) 0 0 0;
  }

  .continueWatchAsFirstItem {
    margin: 0;
    margin-bottom: var(--g-spacing-40);
  }

  .skeletonContainer {
    padding: 0 var(--g-spacing-16);
  }
}

@include breakpoints.max-width-480 {
  .promoOfferButtonsBlock {
    flex-direction: column;
    width: 100%;
  }

  .promoOfferButton {
    margin: 0;
    margin-bottom: var(--g-spacing-16);
    width: 100%;
  }
}
</style>
