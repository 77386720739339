<template>
  <section :class="$style.promoKinomsSlider">
    <app-slider
      ref="slider"
      :items="items"
      :breakpoints="sliderBreakpoints"
      :loop="true"
      :autoplay="true"
      :scroll-on-wheel="false"
      @on-slide-change="onPageChange"
      @on-slide-left="$emit('on-slide-left')"
      @on-slide-right="$emit('on-slide-right')"
    >
      <template #default="{ item, index }">
        <div :class="$style.promoKinomCard" :style="{ height: playerHeightCss }">
          <viju-kinom-video-player
            :current-slide-index="currentPage"
            :show-fullscreen-button="false"
            :has-redirect-kinom-offset="false"
            :slide-index="index"
            :auto-start-load="false"
            :autoplay="true"
            :use-intersect="true"
            :is-first-timeout-loading="index === 0"
            :player-height-css="playerHeightCss"
            :lazy-interaction-loading="true"
            :kinom="item"
            :loop="false"
            :allow-fullscreen="false"
            :muted="true"
            :poster="item.preview"
            :style="{ height: playerHeightCss }"
            :src="item.hls"
            @ended="selectNextItem"
          />
          <app-link
            v-if="item"
            :to="getContentRoute(item, { isPlayerFullscreen: true })"
            @click="onContentLinkClick(item)"
          >
            <div :class="$style.infoWrapper">
              <app-img
                v-if="item.logo"
                :src="item.logo"
                :width="330"
                :image-class="$style.logoImage"
                :lazy="index !== 0"
              />
              <p v-else :class="$style.logoStub">{{ item.contentTitle }}</p>
              <app-button
                :text="isTinyView ? ' ' : primaryButtonText"
                :class="$style.primaryButton"
                :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'kinomSliderPrimaryButton')"
              >
                <template #icon>
                  <icon-video-state v-if="isTinyView" mode="play" size="small" />
                </template>
              </app-button>
            </div>
          </app-link>
        </div>
      </template>
    </app-slider>
  </section>
</template>

<script lang="ts" setup>
import useContentMediaButtonTexts from '@package/content-utils/src/code/content-media-button-texts';
import { useContentPageAnalytics } from '@package/sdk/src/analytics';
import type { ContentMoment } from '@package/sdk/src/api';
import { throttle } from '@package/sdk/src/core';
import { useWindowSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import type { ComponentPublicInstance } from 'vue';
import { ref } from 'vue';

import useWindowWidthPlayerRatio from '@/code/player/use-window-width-player-ratio';
import type { SliderBreakpoints } from '@/components/app-slider/AppSlider.vue';
import IconVideoState from '@/components/icons/IconVideoState.vue';
import AppButton from '@/components/ui/AppButton.vue';
import AppImg from '@/components/ui/AppImg.vue';
import AppLink from '@/components/ui/AppLink.vue';
import VijuKinomVideoPlayer from '@/components/video-player/VijuKinomVideoPlayer.vue';
import useMobile from '@/platform/layout/use-mobile';
import useLocale from '@/platform/localization/use-locale';
import { useRouteUtils } from '@/platform/router/use-route-utils';
import { useCatalogStore } from '@/stores/use-catalog-store';
import { useOffersStore } from '@/stores/use-offers-store';
import { usePromocodeStore } from '@/stores/use-promocode-store';
import { useSessionStore } from '@/stores/use-session-store';

const contentPageAnalytics = useContentPageAnalytics();
const catalogStore = useCatalogStore();

const isMobile = useMobile();
const { getContentRoute } = useRouteUtils();
const { getMyChannelPlayButtonText } = useContentMediaButtonTexts();
const { width } = useWindowSize();
const { playerHeight } = useWindowWidthPlayerRatio();
const { translateContentButtonText } = useLocale();

const { currentOffer, hasOfferTrial, isPartnerSubscription, isActiveSubscription, subscription } =
  storeToRefs(useOffersStore());
const { promocodeCheckInfo } = storeToRefs(usePromocodeStore());
const { isAuth } = storeToRefs(useSessionStore());

withDefaults(
  defineProps<{
    items: ContentMoment[];
  }>(),
  {
    items: () => [],
  },
);

const emit = defineEmits<{
  (e: 'on-slide-change', value: number): void;
  (e: 'on-slide-left'): void;
  (e: 'on-slide-right'): void;
}>();

const sliderBreakpoints: SliderBreakpoints = {
  mobile: 1,
  smallTablet: 1,
  mediumTablet: 1,
  bigTablet: 1,
  smallDesktop: 1,
  mediumDesktop: 1,
  bigDesktop: 1,
  largeDesktop: 1,
};

const currentPage = ref(0);
const slider = ref<ComponentPublicInstance<{ currentSlide: number; nextSlide: () => void }>>();

const isTinyView = computed(() => width.value < 380);

const playerHeightCss = computed(() => {
  if (isMobile) {
    return playerHeight.value;
  }

  return '75vh';
});

const primaryButtonText = computed(() =>
  translateContentButtonText(
    getMyChannelPlayButtonText({
      isAuth: isAuth.value,
      isActiveSubscription: isActiveSubscription.value,
      isPartnerSubscription: isPartnerSubscription.value,
      offer: currentOffer.value,
      subscribePromoTexts: {
        subscribeButtonText: promocodeCheckInfo.value?.promoOffer?.subscribeButtonText,
        subscribeButtonSubtext: promocodeCheckInfo.value?.promoOffer?.subscribeButtonSubtext,
      },
      hasTrialOffer: hasOfferTrial.value,
      subscription: subscription.value,
      isForceSimpleWatchButtonText: true,
    }),
  ),
);

const onPageChange = (slide: number) => {
  currentPage.value = slide;
  emit('on-slide-change', currentPage.value);
};

const onContentLinkClick = (item: ContentMoment) => {
  catalogStore.setCurrentLinkedContentKinom(item);
  contentPageAnalytics.onGotoItemPageFromPromoSlider(item);
};

const selectNextItem = throttle(() => {
  if (!slider.value) {
    return;
  }

  slider.value?.nextSlide();
}, 500);
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.promoKinomsSlider {
  padding: 0 var(--g-spacing-48);
  overflow: hidden;
}

.promoKinomCard {
  position: relative;
}

.infoWrapper {
  position: absolute;
  bottom: var(--g-spacing-48);
  left: var(--g-spacing-48);
}

.logoImage {
  width: 295px;
}

.logoStub {
  @include fonts.WebSubtitle-1;
}

.primaryButton {
  margin-top: var(--g-spacing-24);
}

@include breakpoints.max-width-800 {
  .promoKinomsSlider {
    padding: 0 var(--g-spacing-20);
  }

  .headerSlider {
    padding: 0 var(--g-spacing-16);
  }

  .infoWrapper {
    bottom: var(--g-spacing-16);
    left: var(--g-spacing-16);
  }
}

@include breakpoints.max-width-540 {
  .infoWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    cursor: pointer;
    width: calc(100% - 32px);
  }

  .logoImage {
    max-width: 130px;
  }

  .primaryButton {
    min-width: fit-content;
    margin: 0 0 0 var(--g-spacing-8);
    padding: var(--g-spacing-12) !important;
  }
}
</style>
