<template>
  <player-playlist
    :items="items"
    :link="getContentRoute(selectedItem, { isPlayerFullscreen: true })"
    :selected-item="selectedItem"
    :src="selectedItem.hls"
    :title="selectedItem.title"
    :playlist-title="playlistTitle"
    @ready="onPlayerReady"
    @select-item="onSelectItem"
    @change-selected="onSelectItemById"
    @click-content-link="onContentLinkClick"
    @ended="onEnded"
  />
</template>

<script lang="ts" setup>
import type { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import { AnalyticPageName, ItemPageFrom, useContentPageAnalytics, useKinomAnalytics } from '@package/sdk/src/analytics';
import type { ContentMoment } from '@package/sdk/src/api';
import { ref } from 'vue';

import { useRouteUtils } from '../../platform/router/use-route-utils';
import PlayerPlaylist from '../player-playlist/PlayerPlaylist.vue';

interface Props {
  items: ContentMoment[];
  playlistTitle: string;
}

const props = defineProps<Props>();

const { getContentRoute } = useRouteUtils();
const contentPageAnalytics = useContentPageAnalytics();
const kinomAnalytics = useKinomAnalytics();

const selectedItem = ref<ContentMoment>(props.items[0]);
const player = ref<VijuPlayer>();

const onEnded = (isLastItem: boolean) => {
  if (isLastItem) {
    selectedItem.value = props.items[0];
  }
};

const onSelectItemById = (item: ContentMoment) => {
  if (!props.items) {
    return;
  }

  selectedItem.value = props.items.find((moment) => moment.id === item.id) || props.items[0];
};

const onSelectItem = (item: ContentMoment) => {
  selectedItem.value = item;

  kinomAnalytics.onClickKinomSelect({
    page: AnalyticPageName.Kinom,
    moment: item,
  });
};

const onPlayerReady = (plr: VijuPlayer) => {
  player.value = plr;
};

const onContentLinkClick = () => {
  contentPageAnalytics.onGotoItemPage({
    title: selectedItem.value.contentTitle,
    contentType: selectedItem.value.contentType,
    page: AnalyticPageName.Main,
    itemId: selectedItem.value.contentId,
    kinomId: selectedItem.value.id,
    episodeId: selectedItem.value.episodeId,
    from: ItemPageFrom.Playlist,
    playlistTitle: props.playlistTitle,
  });
};
</script>
