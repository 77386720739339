<template>
  <div :class="$style.channels">
    <h2 :class="$style.channelsTitle">{{ title }}</h2>

    <app-slider :breakpoints="sliderBreakpoints" :items="channels">
      <template v-slot="{ item }">
        <tv-channel-card
          :id="item.id"
          :key="item.id"
          :link="{
            name: $AppRoute.TVChannel,
            params: { id: item.slug },
          }"
          :channel="item"
          :class="$style.channelCard"
          @click="onTvChannelClick(item)"
        >
          <template v-slot:background>
            <app-img
              v-if="item.currentProgram.background"
              :width="$isMobile ? 480 : 640"
              :src="item.currentProgram.background"
              :image-class="$style.background"
              :lazy="true"
              :class="$style.backgroundImage"
            />
          </template>
        </tv-channel-card>
      </template>
    </app-slider>
  </div>
</template>

<script lang="ts" setup>
import { useTvPageAnalytics } from '@package/sdk/src/analytics';
import type { Channel } from '@package/sdk/src/api';

import type { SliderBreakpoints } from '../app-slider/AppSlider.vue';
import AppImg from '../ui/AppImg.vue';
import TvChannelCard from './TvChannelCard.vue';

defineProps<{
  title: string;
  channels: Channel[];
}>();

const tvPageAnalytics = useTvPageAnalytics();
const { $contentCacheManager } = useNuxtApp();

const onTvChannelClick = (item: Channel) => {
  $contentCacheManager.addToCache(item);

  tvPageAnalytics.onGoToTvChannelPage(item);
};

const sliderBreakpoints: SliderBreakpoints = {
  mobile: 1,
  smallTablet: 2,
  mediumTablet: 2,
  bigTablet: 3,
  smallDesktop: 4,
  mediumDesktop: 4,
  bigDesktop: 6,
  largeDesktop: 6,
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '../../assets/breakpoints' as breakpoints;

.channels {
  display: flex;
  padding: 0 var(--g-spacing-48);
  width: 100%;
  flex-direction: column;
}

.channelsTitle {
  @include fonts.WebTitle-2;
  margin-bottom: var(--g-spacing-32);
}

.channelCard {
  position: relative;
  height: 100%;
}

.background {
  width: 100%;
  height: 100%;
}

.backgroundImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo {
  position: absolute;
  bottom: 20px;
  left: 5px;
  max-height: 26px;
}

@include breakpoints.max-width-800 {
  .channels {
    padding: 0 var(--g-spacing-20);
  }
}
</style>
