import type { ApiMainPage, MainPage } from '@package/sdk/src/api';
import { MainPageMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '../../platform/http';

export interface IMainPageFetchBlockOptions {
  page: number;
  perPage?: number;
}

export function useMainPageApi() {
  const { $http } = useNuxtApp();

  const fetchBlocks = (options?: IMainPageFetchBlockOptions): Promise<MainPage> => {
    return $http
      .get<ApiMainPage>(ENDPOINTS.PAGES_MAIN, {
        query: {
          page: options?.page,
          ...(options?.perPage && {
            per_page: options?.perPage,
          }),
        },
      })
      .then(MainPageMapper.map);
  };

  return { fetchBlocks };
}
